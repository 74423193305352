
import $ from "jquery";
import { barbaInit } from "modules/barba";

import {
  // fancybox,
  headerSticked,
  mobileNavigation,
  popupInit,
  videoBlock
} from "./components";

$(document).ready(init);

function init() {
  barbaInit();
  popupInit();
  headerSticked();
  videoBlock();
  mobileNavigation();
  // fancybox();
}

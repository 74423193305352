export function videoBlock() {
  $(document).on('click', '.video-block-play', (e) => {
    const $t = $(e.currentTarget);
    const $parent = $t.parent('.video-block');
    const video = $parent.find('video').get(0);
    $parent.addClass('playing');
    video.setAttribute('controls', 'controls');
    video.play();
    setTimeout(() => {
      $t.hide();
    }, 600);
  });
}

export function addClassSvgEl($el, className) {
  const itemClasses = $el.attr('class');

  $el.attr('class', itemClasses + ' ' + className);
}

export function removeClassSvgEl($el, className) {
  const itemClasses = $el.attr('class');

  const replace = new RegExp(' ' + className, 'g');

  $el.attr('class', itemClasses.replace(replace, ''));
}

export function addSlashToPath(path) {
  let newPath = path;

  if (newPath.slice(-1) !== '/') {
    newPath += '/';
  }

  return newPath;
}

export function isMobile() {
  return $(window).width() < 768;
}

export function isTablet() {
  return $(window).width() < 992;
}

export function addZero(n) {
  return (n < 10 ? '0' : '') + n;
}

export function randomInteger(min, max) {
  let rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
}


import { WHITE_MAP_STYLES } from './map-style';
import { createPopupClass } from './map-popup-class';

export class Neighborhood {
  $preview = $('.neighborhood-preview');
  $neighborhoodMap = $('#neighborhood-map');
  $neighborhoodMapPopups = $('.neighborhood-map-popups');
  $mapNav = $('.neighborhood-map-navigation');
  $mapNavType = $('.neighborhood-map-navigation-type');
  $mapClose = $('.neighborhood-map-close');
  $mapSidebar = $('.neighborhood-map-sidebar');
  posMarkers = {};
  infoWindows = {};
  clickMarkers = {};
  mapMarkers = {};
  icons;
  map;

  constructor() {
    this.init();
  }

  init() {
    this.initNav();
    this.initIcons();
    this.initMap();
    this.initMarkers();
    this.initPointNav();
    this.initCloseMap();
  }

  initIcons() {
    this.icons = {
      bars: '/images/neighborhood/bars',
      dining: '/images/neighborhood/dining',
      'arts-culture': '/images/neighborhood/arts-culture',
      'grocery-stores': '/images/neighborhood/grocery-stores',
      fitness: '/images/neighborhood/fitness',
    };
  }

  initNav() {
    this.$mapNav.find('.neighborhood-map-navigation-title').on('click', (e) => {
      const $t = $(e.currentTarget);
      const type = $t.parent().data('type');
      const $buttonsMobile = $('.neighborhood-map-button-mobile');
      $buttonsMobile.removeClass('show');
      this.$mapSidebar.removeClass('show');

      if($t.parent().is('.active')) return;

      this.$mapNav.find('.active').removeClass('active');
      $t.parent().addClass('active');
      this.$mapNav.find('.neighborhood-map-navigation-section:not(.active)').addClass('hide');
      this.showVisibleMarkers();
      this.showTypeMarker(type);
    });

    this.$mapNav.find('.neighborhood-map-navigation-section-close').on('click', (e) => {
      const $t = $(e.currentTarget);
      $t.parent().removeClass('active');
      this.$mapNav.find('.neighborhood-map-navigation-section').removeClass('hide');
      this.showVisibleMarkers();
    })
  }

  initMap() {
    if (!this.$neighborhoodMap.length) return;
    const mapContainer = document.getElementById('neighborhood-map');

    this.map = new google.maps.Map(mapContainer, {
      zoom: parseInt(mapContainer.dataset.zoom),
      center: {
        lat: parseFloat(mapContainer.dataset.lat),
        lng: parseFloat(mapContainer.dataset.lng),
      },
      disableDefaultUI: true,
      styles: WHITE_MAP_STYLES,
    });

    const transitLayer = new google.maps.TransitLayer();
    transitLayer.setMap(this.map);

    const building = new google.maps.Marker({
      position: new google.maps.LatLng(
        parseFloat(mapContainer.dataset.lat),
        parseFloat(mapContainer.dataset.lng)
      ),
      map: this.map,
      icon: '/images/neighborhood/point-logo.svg',
      anchor: new google.maps.Point(40, 35),
      scaledSize: new google.maps.Size(80, 71),
    });

    $('.neighborhood-map-button-mobile')
      .on('click', (e) => {
        const $t = $(e.currentTarget);
        if ($t.is('.show')) {
          $t.removeClass('show');
          this.$mapSidebar.removeClass('show');
        } else {
          $t.addClass('show');
          this.$mapSidebar.addClass('show');
        }
      });
  }

  initMarkers() {
    const Popup = createPopupClass();
    $.getJSON('/neighborhood.json', (data) => {
      this.mapMarkers = data['data'];

      const markerCounts = {
        bars: 0,
        dining: 0,
        artsCulture: 0,
        groceryStores: 0,
        fitness: 0,
        transportation: 0
      };

      for (let marker in this.mapMarkers) {
        const currentMarker = this.mapMarkers[marker];

        let num;

        if (currentMarker.type === 'bars') {
          markerCounts.bars++;
          num = markerCounts.bars;
        }

        if (currentMarker.type === 'dining') {
          markerCounts.dining++;
          num = markerCounts.dining;
        }

        if (currentMarker.type === 'arts-culture') {
          markerCounts.artsCulture++;
          num = markerCounts.artsCulture;
        }

        if (currentMarker.type === 'grocery-stores') {
          markerCounts.groceryStores++;
          num = markerCounts.groceryStores;
        }

        if (currentMarker.type === 'fitness') {
          markerCounts.fitness++;
          num = markerCounts.fitness;
        }

        if (currentMarker.type === 'transportation') {
          markerCounts.transportation++;
          num = markerCounts.transportation;
        }

        this.$mapNav
          .find(
            '.neighborhood-map-navigation-section[data-type="' +
              currentMarker.type +
              '"] ul'
          )
          .append(
            `<li class="neighborhood-map-navigation-point" id="${currentMarker.id}"><span class="number">${num}</span>${currentMarker.content}</li>`
          );

        this.posMarkers[marker] = new google.maps.Marker({
          position: new google.maps.LatLng(
            currentMarker.lat,
            currentMarker.lng
          ),
          label: {
            text: num.toString(),
            fontFamily: 'Manrope, sans-serif',
            fontSize: '16px',
            color: '#ffffff',
          },
          map: this.map,
          icon: this.icons[currentMarker.type] + '.svg',
          visible: currentMarker.visible,
          anchorPoint: new google.maps.Point(18, 0),
          scaledSize: new google.maps.Size(37, 41),
        });

        const div = $(`
                    <div id="neighborhood-map-info-popup-${marker}">
                    <div class="content type-${currentMarker.type}">
                    <div class="text">${currentMarker.content}</div>
                    </div>
                `);

        this.$neighborhoodMapPopups.append(div);

        this.infoWindows[marker] = new Popup(
          new google.maps.LatLng(currentMarker.lat, currentMarker.lng),
          document.getElementById('neighborhood-map-info-popup-' + marker)
        );

        this.infoWindows[marker].setMap(this.map);

        this.clickMarkers[marker] = ((marker) => {
          return (e) => {
            const pos = this.posMarkers[marker].getPosition();
            this.map.panTo(pos);

            this.showMarkers();
            this.posMarkers[marker].setIcon('/images/neighborhood/icon-hover.svg');

            this.$mapNavType.find('.active').removeClass('active');
            $('#' + currentMarker.id).addClass('active');

            $('[id^=neighborhood-map-info-popup-]').parent().hide();
            $('#neighborhood-map-info-popup-' + marker)
              .parent()
              .show();
          };
        })(marker);

        this.posMarkers[marker].addListener('click', this.clickMarkers[marker]);
      }
    });
  }

  initPointNav() {
    $(document).on('click', '.neighborhood-map-navigation-point', (e) => {
      let index = undefined;
      const $t = $(e.currentTarget);

      for (let marker in this.mapMarkers) {
        if (this.mapMarkers[marker].id === parseInt($t.attr('id'))) {
          index = marker;
          this.map.panTo(this.posMarkers[marker].getPosition());

          this.showMarkers();
          this.posMarkers[marker].setIcon('/images/neighborhood/icon-hover.svg');
        }
      }
      $('.neighborhood-map-navigation-point').removeClass('active');
      $t.addClass('active');
      $('[id^=neighborhood-map-info-popup-]').parent().hide();
      $('#neighborhood-map-info-popup-' + index)
        .parent()
        .show();
    });
  }

  initCloseMap() {
    this.$mapClose.on('click', () => {
      this.$preview.css('display', 'flex').hide().fadeIn(300);
    });
  }

  showMarkers() {
    for (let marker in this.mapMarkers) {
      this.posMarkers[marker].setOpacity(1);
      const markerType = this.mapMarkers[marker].type;
      this.posMarkers[marker].setIcon(this.icons[markerType] + '.svg');
    }
  }

  showVisibleMarkers() {
    for (let marker in this.mapMarkers) {
      this.posMarkers[marker].setOpacity(1);
      this.posMarkers[marker].setVisible(true);
      const markerType = this.mapMarkers[marker].type;
      this.posMarkers[marker].setIcon(this.icons[markerType] + '.svg');
      $('#neighborhood-map-info-popup-' + marker)
        .parent()
        .hide();
    }
  }

  showTypeMarker(type) {
    for (let marker in this.mapMarkers) {
      this.posMarkers[marker].setOpacity(1);

      if (type === this.mapMarkers[marker].type) {
        this.posMarkers[marker].setVisible(true);
        $('#neighborhood-map-info-popup-' + marker)
          .parent()
          .hide();
      } else {
        this.posMarkers[marker].setVisible(false);

        $('#neighborhood-map-info-popup-' + marker)
          .parent()
          .hide();
      }
    }
  }
}

import ImageCompare from 'image-compare-viewer';

export function imageCompare() {
  const $images = $('.image-compare');
  if(!$images || $images.length === 0) return;

  const options = {
    controlShadow: false,
    addCircle: true,
  }

  $images.each((i, el) => {
    new ImageCompare(el, options).mount();
  })
}

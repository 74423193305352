import AirDatepicker from "air-datepicker";
import localeEn from "air-datepicker/locale/en";
import { isTablet } from "../modules/functions";
import $ from "jquery";

export function datepickerInit() {
  var datepickerParams = {
    locale: localeEn,
    minDate: new Date(),
    altField: "#feedback_date",
    altFieldDateFormat: "MM/dd/yyyy",
  };

  new AirDatepicker("#date", datepickerParams);

  new AirDatepicker(
    "#date_mobile",
    jQuery.extend(datepickerParams, {
      isMobile: true,
      autoClose: true,
    })
  );

  let date = $("#date");
  let date_mobile = $("#date_mobile");

  $(window).on("resize", (e) => {
    if (isTablet()) {
      date.removeAttr("required");
      date_mobile.attr("required", true);
    } else {
      date_mobile.removeAttr("required");
      date.attr("required", true);
    }
  });
}

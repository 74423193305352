export function activeNavigation() {
  const path = window.location.pathname.split('/');
  const $navigation = $('.header-navigation');

  if (path[1] && path[1].length > 0) {
    $navigation.find('.active').removeClass('active');
    $navigation.find('a[href^="/' + path[1] + '"]').addClass('active');
  } else {
    $navigation.find('.active').removeClass('active');
  }
}

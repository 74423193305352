import 'select2/dist/js/select2.full.min';

export function selectInit() {

    $('.custom-select').each((i, el) => {
        const $el = $(el);
        $el.select2({
            placeholder: $el.data('placeholder'),
            minimumResultsForSearch: 20
        });
    });
}

import Swiper, { EffectFade, Navigation, Thumbs } from 'swiper';
import SwiperCore from 'swiper';

export function gallerySlider() {
  const $slider = $('.gallery-slider');
  if(!$slider || $slider.length === 0) return;
  SwiperCore.use([Navigation, Thumbs, EffectFade]);

  $('.gallery-close').on('click', () => {
    history.back();
  });

  const thumbs = new Swiper('.gallery-thumb-slider', {
    slidesPerView: 'auto',
    spaceBetween: 11,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  })

  const slider = new Swiper('.gallery-slider', {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: thumbs
    },
    navigation: {
      prevEl: '.gallery-slider-prev',
      nextEl: '.gallery-slider-next'
    }
  });
}

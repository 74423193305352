class Global {
    constructor() {
        /**
         *
         * @type {boolean}
         */
        this.changeHeaderSticked = true;
    }
}


export default (new Global);

import barba from '@barba/core';

export class Availability {
  $floors;
  $floor;
  constructor() {
    this.init();
  }

  init() {
    this.$floors = $('.floors');
    this.$floor = $('.floor');

    if (this.$floors && this.$floors.length > 0) this.floorsInit();
    if (this.$floor && this.$floor.length > 0) this.floorInit();
  }

  floorsInit() {
    const $numbers = $('.availability-floors-number');
    this.$floors.find('.floors-item').on('mouseenter', (e) => {
      const $t = $(e.currentTarget);

      $numbers.addClass('show');
      $numbers.find('span').text($t.data('floor'));
    });

    this.$floors.find('.floors-item').on('mouseleave', () => {
      $numbers.removeClass('show');
    });

    this.$floors.find('.floors-item').on('click', (e) => {
      const $t = $(e.currentTarget);

      barba.go($t.data('link'));
    });
  }

  floorInit() {
    const $unitName = $('.availability-floor-unit-name');
    this.$floor.find('.floor-item').on('mouseenter', (e) => {
      const $t = $(e.currentTarget);
      const offset = $t.offset();
      const sidebarWidth = $('.availability-sidebar').innerWidth();
      const elWidth = $t.get(0).getBoundingClientRect().width;

      const left = $t.offset().left - sidebarWidth + elWidth;

      $unitName.text($t.data('name'));
      $unitName.css({ top: offset.top - 10, left: left - 40 });
      $unitName.addClass('show');
    });

    this.$floor.find('.floor-item').on('mouseleave', (e) => {
      const $related = $(e.relatedTarget);
      const $t = $(e.currentTarget);
      if ($related.is('.availability-floor-unit-name')) {
        $t.addClass('active');
        $unitName.on('mouseleave.unitName', (e) => {
          const $related2 = $(e.relatedTarget);
          if (!$related2.is('.floor-item')) {
            $unitName.removeClass('show');
          }
          $t.removeClass('active');
          $unitName.off('mouseleave.unitName');
        });
      } else {
        $unitName.removeClass('show');
      }
    });

    this.$floor.find('.floor-item').on('click', (e) => {
      const $t = $(e.currentTarget);

      barba.go($t.data('link'));
    });
  }
}

import Swiper, { Autoplay, EffectFade, Pagination } from 'swiper';
import SwiperCore from 'swiper';
import { addZero } from '../../modules/functions';

export function homeSlider() {
  const $slider = $('.home-slider');
  if(!$slider || $slider.length === 0) return;

  SwiperCore.use([Pagination, Autoplay, EffectFade]);

  $slider.find('.home-slider-animate').each((i, el) => {
    const $t = $(el);
    const imageLink = $t.find('img').attr('src');
    $t.find('.home-slider-image').css('background-image', `url('${imageLink}')`);
  });

  const swiper = new Swiper('.home-slider', {
    allowTouchMove: false,
    autoplay: {
      delay: 7000
    },
    pagination: {
      type: 'custom',
      el: '.home-slider-pagination',
      renderCustom: function (swiper, current, total) {
        return `<span class="home-slider-pagination-current">${addZero(current)}</span>
                <span class="home-slider-pagination-progress"></span>
                <span class="home-slider-pagination-total">${addZero(total)}</span>`;
      }
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    }
  });
}

import barba from "@barba/core";
import Cookies from "js-cookie";
import {
  activeNavigation,
  checkSticked,
  imageCompare,
  openPopup,
  closePopup,
  selectInit,
  datepickerInit,
} from "../components";
import { gallerySlider, homeSlider } from "../components/sliders";
import { Neighborhood } from "../modules/neighborhood";
import { Availability } from "../modules/availability";
import $ from "jquery";
import { isTablet } from "../modules/functions";
import global from "../global";

export function barbaInit() {
  const $body = $("body");
  const $header = $(".header");

  window.closePopup = closePopup;

  barba.hooks.enter(() => {
    window.scrollTo(0, 0);
  });
  barba.init({
    views: [
      {
        namespace: "home",
        afterEnter(data) {
          activeNavigation();
          global.changeHeaderSticked = false;
          $body.addClass("overflow-hidden hide-footer");
          $body.removeClass("hide-header sticked");

          homeSlider();

          /* todo change logic next line */
          if (typeof Cookies.get("popup") == "undefined") {
            openPopup($("#welcome-popup"));
          }
        },
      },
      {
        namespace: "second",
        afterEnter(data) {
          activeNavigation();
          global.changeHeaderSticked = true;
          imageCompare();
          $body.removeClass("overflow-hidden hide-footer hide-header");
          checkSticked();
        },
      },
      {
        namespace: "availability",
        afterEnter(data) {
          activeNavigation();
          global.changeHeaderSticked = false;
          $body.removeClass("overflow-hidden hide-header");
          $body.addClass("hide-footer");
          $header.addClass("header-sticked");

          selectInit();
          const availability = new Availability();
        },
      },
      {
        namespace: "gallery",
        afterEnter(data) {
          activeNavigation();
          global.changeHeaderSticked = false;
          $body.addClass("overflow-hidden hide-footer");
          if (isTablet()) {
            $body.removeClass("hide-header");
            $header.addClass("header-sticked");
          } else {
            $body.addClass("hide-header");
          }

          gallerySlider();
        },
      },
      {
        namespace: "contact",
        afterEnter(data) {
          activeNavigation();
          datepickerInit();
          global.changeHeaderSticked = false;
          $body.addClass("hide-footer");
          $body.removeClass("hide-header");
          $header.addClass("header-sticked");
          const date = document.getElementById('date');
          const date_mobile = document.getElementById('date_mobile');

          if (isTablet()) {
            $body.removeClass("overflow-hidden");
            date.removeAttribute('required'); 
          } else {
            $body.addClass("overflow-hidden");
            date_mobile.removeAttribute('required'); 
          }

          selectInit();
        },
      },
      {
        namespace: "neighborhood",
        afterEnter(data) {
          activeNavigation();
          global.changeHeaderSticked = false;
          $body.addClass("overflow-hidden hide-footer");
          if (isTablet()) {
            $body.removeClass("hide-header");
            $header.addClass("header-sticked");
          } else {
            $body.addClass("hide-header");
          }

          const neighborhood = new Neighborhood();
        },
      },
      {
        namespace: "privacy",
        afterEnter(data) {
          activeNavigation();
          $body.removeClass("overflow-hidden hide-footer hide-header");
          checkSticked();
        },
      },
    ],
    transitions: [
      {
        name: "to-all",
        enter() {},
        leave(data) {},
        after(data) {},
      },
    ],
  });
}

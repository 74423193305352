import Cookies from "js-cookie";

const animationDuration = 600;

export function popupInit() {
  $(document).on("click", ".popup-open", e => {
    e.preventDefault();
    let target;
    const href = $(e.currentTarget).attr("href");
    if (!href) {
      target = $(e.currentTarget).data("popup");
    } else {
      target = href;
    }

    if (!target) return;

    const $popup = $(target);
    openPopup($popup);
  });

  $(document).on("click", ".popup-close", e => {
    e.preventDefault();
    closePopup($(e.currentTarget).parents(".popup"));
  });

  $(document).on("click", ".popup", e => {
    if (!$(e.target).closest(".popup-container").length) {
      closePopup($(e.currentTarget));
    }
  });
}

/**
 *
 * @param {jQuery} $popup
 */
export function openPopup($popup) {
  $("body").addClass("overflow-hidden-popup");
  $popup.addClass("popup-show");
  Cookies.set("popup", "1", { expires: 7, path: "/" });
}

/**
 *
 * @param {jQuery} $parent
 */
export function closePopup($parent) {
  $("body").removeClass("overflow-hidden-popup");
  $parent.addClass("popup-hide");

  setTimeout(() => {
    $parent.removeClass("popup-show").removeClass("popup-hide");
  }, animationDuration);
}

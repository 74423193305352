export function mobileNavigation() {
  const $b = $('body');
  const $button = $('.button-burger');
  const $mobileNavigation = $('.mobile-navigation');

  $button.on('click', (e) => {
    e.preventDefault();
    if ($b.is('.mobile-navigation-show')) {
      $b.removeClass('mobile-navigation-show');
      $mobileNavigation.addClass('hide');

      setTimeout(() => {
        $mobileNavigation.removeClass('show hide');
      }, 600);
    } else {
      $b.addClass('mobile-navigation-show');
      $mobileNavigation.addClass('show');
    }
  });

  $mobileNavigation.find('a').on('click', () => {
    $b.removeClass('mobile-navigation-show');
    $mobileNavigation.addClass('hide');

    setTimeout(() => {
      $mobileNavigation.removeClass('show hide');
    }, 600);
  });
}

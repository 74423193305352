import globalVar from '../global';

export function headerSticked() {
  const $w = $(window);
  if(globalVar.changeHeaderSticked) {
    checkSticked();
  }

  $w.on('scroll', () => {
    if(globalVar.changeHeaderSticked) {
      checkSticked();
    }
  })
}

export function checkSticked() {
  const $w = $(window);
  const $header = $('.header');

  if($w.scrollTop() > 20) {
    $header.addClass('header-sticked');
  } else {
    $header.removeClass('header-sticked');
  }
}
